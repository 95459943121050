<template>
  <div>
    <div class="modal fade in" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <slot name="header">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <img src="/static/img/images/icons/close-modal/svg" />
              </button>
            </slot>
          </div>
          <div class="modal-body">
            <slot name="body">
              <h1>Default Body Modal</h1>
            </slot>
          </div>
          <div class="modal-footer">
            <slot name="footer"> </slot>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <!-- /.modal -->
    <div class="modal-backdrop fade in"></div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
};
</script>
