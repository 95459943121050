import '@/shared/socio-lib';

class ProductDetailHelper {
  appendAttributesProduct(createdArrAttributes, { attribute_group_name, attribute_name, id_attribute }) {
    $SO.each(createdArrAttributes, (key, obj) => {
      if (attribute_group_name in obj) {
        let isNotExist = true;
        $SO.each(obj[attribute_group_name].data, (keyData, data) => {
          if (data.name === attribute_name) {
            isNotExist = false;
          }
        });

        if (isNotExist) {
          createdArrAttributes[key][attribute_group_name].data.push({
            name: attribute_name,
            id: id_attribute,
            isActive: false,
          });
        }
      }
    });
    return createdArrAttributes;
  }

  checkingAttributeExist(createdArrAttributes, attribute_group_name) {
    let notExist = false;
    $SO.each(createdArrAttributes, (key, attributeCollection) => {
      if (attribute_group_name in attributeCollection) {
        notExist = true;
      }
    });

    return notExist;
  }
}

const instance = new ProductDetailHelper();
Object.freeze(instance);

export default instance;
