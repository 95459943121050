import ls from '@/components/helpers/local-storage';
import { get } from 'lodash';

let CancelToken;
let source;

const Config = require('~/config/default.env').default;

const CART_ACTION = {
  EMPTY: 'EMPTY',
  EXIST: 'EXIST',
};

export default {
  state: {
    global_loading: false,
    order_cancelled_loading: false,
    idle_banner: [],
    loading_banner: true,
    product_list: [],
    loading_product: false,
    loading_loadmore_product: false,
    banner_footer: [],
    quickView: {
      isShow: false,
      data: null,
      productId: '',
      currentCombination: '',
    },
    addToBag: {
      isShow: false,
      isLoading: false,
      data: null,
      productId: '',
      currentCombination: '',
    },
    notificationAddCart: {
      isShow: false,
      data: null,
      combination_group_name: '',
    },
    loadingCart: false,
    footer_cart: [],
    point_applied: 0,
    cart_qty: 0,
    cart_error: false,
    cart_fetched: false,
    cart_processing: false,
    statusCart: false,
    dataOrder: {},
    cart_price_total: { cart: 0, discount: 0, delivery: 0 },
    skip: 0,
    limit: 58,

    carts: null,

    cola_idle_screen: [],
    cola_header_banner: [],
    cola_primary_home_banner: [],
    cola_secondary_home_banner: [],
    cola_primary_footer_banner: [],
    cola_secondary_footer_banner: [],
  },
  mutations: {
    SET_GLOBAL_LOADING(state, data) {
      state.global_loading = data;
    },
    SET_ORDER_CANCELLED_LOADING(state, data) {
      state.order_cancelled_loading = data;
    },
    LOADING_BANNER(state, data) {
      state.loading_banner = data;
    },
    LOADING_LOADMORE_PRODUCT(state, data) {
      state.loading_loadmore_product = data;
    },
    PRODUCT_LIST(state, data) {
      state.product_list = data;
    },
    LOADING_PRODUCT(state, data) {
      state.loading_product = data;
    },

    IDLE_BANNER(state, data) {
      state.idle_banner = data;
    },
    BANNER_FOOTER(state, data) {
      state.banner_footer = data;
    },

    COLA_IDLE_SCREEN(state, data) {
      state.cola_idle_screen = data;
    },
    COLA_HEADER_BANNER(state, data) {
      state.cola_header_banner = data;
    },
    COLA_PRIMARY_HOME_BANNER(state, data) {
      state.cola_primary_home_banner = data;
    },
    COLA_SECONDARY_HOME_BANNER(state, data) {
      state.cola_secondary_home_banner = data;
    },
    COLA_PRIMARY_FOOTER_BANNER(state, data) {
      state.cola_primary_footer_banner = data;
    },
    COLA_SECONDARY_FOOTER_BANNER(state, data) {
      state.cola_secondary_footer_banner = data;
    },

    HIDE_MODAL(state, modalName) {
      state[modalName].isShow = false;
    },
    SHOW_MODAL(state, modalName) {
      state[modalName].isShow = true;
    },
    TOGGLE_LOADING(state, modalName) {
      state[modalName].isLoading = !state[modalName].isLoading;
    },
    SET_QUICK_VIEW_DATA(state, data) {
      const combinations = { size: [], variant: [], shade: [], amount: [] };
      const combinationsNameArray = [];
      const lisCombinationWithCode = {};
      let currentCombination;

      if (data && Object.keys(data).length) {
        currentCombination =
          data.default_combination && data.vending_stock > 0 ? data.default_combination : data.combinations[0];

        if (data.combinations && data.combinations.length) {
          data.combinations.forEach((combination, key) => {
            if (combination.attributes && Object.keys(combination.attributes).length) {
              let combinationCode = '';
              for (const attribute in combination.attributes) {
                let deepCopyAttribute;

                if (combination.attributes.hasOwnProperty(attribute)) {
                  if (
                    combinationsNameArray.indexOf(combination.attributes[attribute].name) < 0 &&
                    attribute !== 'non_specify' &&
                    'id' in combination.attributes[attribute]
                  ) {
                    deepCopyAttribute = JSON.parse(JSON.stringify(combination.attributes[attribute]));
                    deepCopyAttribute.isDisabled = false;
                    combinations[attribute].push(deepCopyAttribute);
                    combinationsNameArray.push(combination.attributes[attribute].name);
                  }

                  if (attribute !== 'non_specify' && 'id' in combination.attributes[attribute]) {
                    combinationCode = combinationCode ? combinationCode + '-' : combinationCode;
                    combinationCode += `${attribute}_${combination.attributes[attribute].id}`;
                  }
                }
              }

              if (combinationCode) {
                lisCombinationWithCode[combinationCode] = combination;
              }
            }
          });
        }

        data.list_combinations = combinations;
        data.list_combinations_with_code = lisCombinationWithCode;
        if (currentCombination && Object.keys(currentCombination).length) {
          state.quickView.currentCombination = JSON.parse(JSON.stringify(currentCombination));
        } else {
          state.quickView.currentCombination = data.default_combination;
        }

        if (combinations.size.length === 0 && combinations.variant.length === 0 && combinations.shade.length === 0) {
          state.quickView.currentCombination.images = 'images' in data ? data.images : [];
        } else {
          if (state.quickView.currentCombination.images && state.quickView.currentCombination.images.length) {
            state.quickView.currentCombination.images = state.quickView.currentCombination.images;
          } else if (data.images && data.images.length) {
            state.quickView.currentCombination.images = data.images;
          } else {
            state.quickView.currentCombination.images = [
              {
                url: 'https://img-sociolla.s3-ap-southeast-1.amazonaws.com/img/widget/quickview/dummy_product.svg',
              },
            ];
          }
        }
      }

      state.quickView.data = data;
    },
    SET_NOTIFICATION_ADD_CART_DATA(state, data) {
      state.notificationAddCart.data = data;
    },
    SET_NOTIFICATION_ADD_CART_COMBINATION_GROUP_NAME(state, value) {
      state.notificationAddCart.combination_group_name = value;
    },
    SET_QUICK_VIEW_CURRENT_COMBINATION(state, data) {
      state.quickView.currentCombination = data;
    },
    SET_QUICK_VIEW_PRODUCT_ID(state, productId) {
      state.quickView.productId = productId;
    },
    loadingCart(state, data) {
      state.loadingCart = data;
    },
    footer_cart(state, data) {
      if (Array.isArray(data)) {
        state.footer_cart = data;
      } else {
        state.footer_cart.unshift(data);
      }
    },
    point_applied(state, data) {
      state.point_applied = (data && data * 100) || 0;
    },
    statusCart(state, data) {
      state.statusCart = data;
    },
    dataOrder(state, data) {
      state.dataOrder = data;
    },
    cart_error(state, data) {
      state.cart_error = data;
    },
    cart_fetched(state) {
      state.cart_fetched = true;
    },
    cart_processing(state, value) {
      state.cart_processing = value;
    },
    cart_qty(state, value = 0) {
      state.cart_qty = value;
    },
    cart_price_total(state, data) {
      state.cart_price_total = data;
    },
    async updateCartQuantity(state, { index, action }) {
      const carts = await JSON.parse(ls.get('cart'));

      if (action === 'plus') {
        state.footer_cart[index].qty += 1;
        state.footer_cart[index].raw.qty += 1;
      } else if (action === 'minus') {
        state.footer_cart[index].qty -= 1;
        state.footer_cart[index].raw.qty -= 1;
        state.footer_cart[index].raw.quantity -= 1;

        if (state.footer_cart[index].qty <= 0) {
          carts.splice(index, 1);
          state.footer_cart.splice(index, 1);
          await ls.set('cart', JSON.stringify(carts));
        }
      } else if (action === 'delete') {
        state.footer_cart[index].qty = 0;
        state.footer_cart[index].raw.qty = 0;

        if (carts?.length && carts[index]) {
          carts.splice(index, 1);
          state.footer_cart.splice(index, 1);
          ls.set('cart', JSON.stringify(carts));
        }
      }
    },
    RESET_SKIP(state) {
      state.skip = 0;
    },
  },
  actions: {
    async getVmColaBanner({ commit, state, dispatch }, data) {
      const locations = [
        'vending_machine_cola_idle_screen',
        'vending_machine_cola_primary_home_banner',
        'vending_machine_cola_secondary_home_banner',
        'vending_machine_cola_header_banner',
        'vending_machine_cola_primary_footer_banner',
        'vending_machine_cola_secondary_footer_banner',
      ];

      const filter = {
        locations: locations,
        'more_info.enabled_in_vending_machine': true,
        is_active: true,
        is_deleted: false,
        start_date: { $lte: new Date() },
        end_date: { $gte: new Date() },
      };

      let vm_detail = await Cookies.get('store_detail');
      if (vm_detail || vm_detail !== '') {
        vm_detail = JSON.parse(vm_detail);

        filter.vending_machine_id = vm_detail?._id;
      } else {
        const store_location_id = await Cookies.get('store_location_id');
        if (store_location_id) {
          filter.vending_machine_id = store_location_id;
        }
      }

      return axios
        .get(`${Config.MS_CATALOG_API_URL}/banners/all`, {
          params: {
            filter: filter,
            sort: '-created_at',
          },
          headers: {
            ['SOC-PLATFORM']: 'vending_machine',
          },
        })
        .then((response) => {
          if (response.status == 200 && response.data.data) {
            const res = response.data.data;
            const cola_banners = [
              { schema: 'vending_machine_cola_idle_screen', commitKey: 'COLA_IDLE_SCREEN' },
              { schema: 'vending_machine_cola_header_banner', commitKey: 'COLA_HEADER_BANNER' },
              { schema: 'vending_machine_cola_primary_home_banner', commitKey: 'COLA_PRIMARY_HOME_BANNER' },
              { schema: 'vending_machine_cola_secondary_home_banner', commitKey: 'COLA_SECONDARY_HOME_BANNER' },
              { schema: 'vending_machine_cola_primary_footer_banner', commitKey: 'COLA_PRIMARY_FOOTER_BANNER' },
              { schema: 'vending_machine_cola_secondary_footer_banner', commitKey: 'COLA_SECONDARY_FOOTER_BANNER' },
            ];

            for (let i = 0; i < cola_banners.length; i++) {
              const { schema, commitKey } = cola_banners[i];

              if (res[schema].length) {
                const result = res[schema];
                const newArr = [];
                for (let j = 0; j < result.length; j++) {
                  const url = result[j].images.filter((url) => url.url);
                  if (url.length) {
                    newArr.push(result[j]);
                  }
                }

                newArr.sort((a, b) => {
                  if (a.position === b.position) {
                    return new Date(b.updated_at) < new Date(a.updated_at) ? -1 : 1;
                  }

                  return a.position - b.position;
                });

                newArr.forEach((b, i) => (b.position = i + 1));

                commit(commitKey, newArr);
              }
            }

            commit('LOADING_BANNER', false);
          }
        })
        .catch((error) => {
          commit('LOADING_BANNER', false);
        });
    },
    async getProductList({ commit, state }, load_more = false) {
      if (state.skip < 60 && (load_more ? !state.loading_loadmore_product : !state.loading_product)) {
        if (!load_more) {
          commit('LOADING_PRODUCT', true);
        } else {
          commit('LOADING_LOADMORE_PRODUCT', true);
        }
        const vmId =
          Cookies.get('location_detail_vm_cola') && Cookies.get('location_detail_vm_cola') !== 'undefined'
            ? Cookies.get('location_detail_vm_cola')
            : '';
        if (vmId) {
          const headers = {
            'SOC-PLATFORM': 'vending-machine',
          };
          const loc = Cookies.get('store_location_id');
          if (loc) {
            headers.store_id = loc;
          }
          await axios
            .get(`${Config.MS_CATALOG_API_URL}/vending-machines/${vmId}/products`, {
              params: {
                skip: state.skip,
                limit: state.limit,
              },
              headers: headers,
            })
            .then(async (response) => {
              if (response.status == 200) {
                let { footer_cart } = state;

                if (!footer_cart?.length) {
                  const savedCart = ls.get('cart');
                  footer_cart = savedCart ? await JSON.parse(savedCart) : [];
                }

                let data = response.data.data;
                if (load_more && state.product_list && state.product_list.length) {
                  data = state.product_list.concat(data);
                }

                if (data && data.length) {
                  data.forEach((p) => {
                    const findProductInCart = (footer_cart || []).find(
                      (cp) => cp?.raw?.combination_id === p?.default_combination?._id
                    );

                    if (findProductInCart) {
                      p.combination = findProductInCart?.raw?.detail?.combination;
                      p.quantities = findProductInCart.qty;
                      p.cart_action = CART_ACTION.EXIST;
                      p.toggle = false;
                    } else {
                      p.quantities = 0;
                      p.cart_action = CART_ACTION.EMPTY;
                      p.toggle = false;
                    }
                  });

                  const products = data.reduce((acc, curr) => {
                    // if (!acc.length) {
                    //   acc.push(curr);
                    //   return acc;
                    // }

                    const findCombIdx = acc.findIndex(
                      (p) => p.default_combination._id === curr.default_combination._id
                    );
                    if (findCombIdx > -1) {
                      if (!acc[findCombIdx].vending_details?.length) {
                        Object.assign(acc[findCombIdx], { vending_details: [] });

                        acc[findCombIdx].vending_details.push(
                          {
                            vending_slot: acc[findCombIdx].vending_slot,
                            vending_stock: acc[findCombIdx].vending_stock,
                          },
                          {
                            vending_slot: curr.vending_slot,
                            vending_stock: curr.vending_stock,
                          }
                        );
                      } else {
                        acc[findCombIdx].vending_details.push({
                          vending_slot: curr.vending_slot,
                          vending_stock: curr.vending_stock,
                        });
                      }
                    } else {
                      acc.push(curr);
                    }

                    return acc;
                  }, []);

                  products.forEach((p) => {
                    p.vending_stock =
                      (p.vending_details || []).reduce((acc, curr) => (acc += curr.vending_stock), 0) ||
                      p.vending_stock;
                  });

                  commit('PRODUCT_LIST', products);
                }
                // Do not reset the cart if the data is empty; just update the product list without changes
                // else {
                //   await ls.set('cart', []);
                //   state.footer_cart = [];
                commit('PRODUCT_LIST', state.product_list);

                commit('LOADING_PRODUCT', false);
                commit('LOADING_LOADMORE_PRODUCT', false);
                state.skip = state.skip + state.limit;
              }
            })
            .catch(() => {
              commit('LOADING_PRODUCT', false);
              commit('LOADING_LOADMORE_PRODUCT', false);
              state.skip = state.skip + state.limit;
            });
        } else {
          commit('PRODUCT_LIST', []);
          commit('LOADING_PRODUCT', false);
          commit('LOADING_LOADMORE_PRODUCT', false);
        }
      }
    },
    async recalculateProducts({ commit, state }) {
      let { footer_cart } = state;

      if (!footer_cart?.length) {
        footer_cart = await JSON.parse(ls.get('cart'));
      }

      const data = state.product_list;
      data.forEach((p) => {
        const findProductInCart = (footer_cart || []).find(
          (cp) => cp?.raw?.combination_id === p?.default_combination?._id
        );
        if (findProductInCart) {
          p.quantities = findProductInCart.qty;
          p.cart_action = CART_ACTION.EXIST;
        } else {
          p.quantities = 0;
          p.cart_action = CART_ACTION.EMPTY;
        }
      });

      commit('PRODUCT_LIST', data);
    },
    async setFooterCart({ commit, state, dispatch, getters }, data) {
      if (state.footer_cart.length == 0 || state.cart_qty == 0) {
        state.footer_cart = [];
        state.cart_qty = 0;
      }

      let state_data = {};
      let combination_attribute = '';

      const cart_qty = get(data, 'quantity', 0) || get(data, 'product.quantity', 0) || get(data, 'raw.quantity', 0);

      let default_price = 0;

      if (data.dataCart) {
        state_data = { ...data.dataCart };
        state_data.product._id = data.product._id;
        state_data.product.detail = { ...data.product };
        combination_attribute = data.dataModalCart.attribute;
        default_price = data.defaultPrice;
      } else {
        state_data = { ...data };
        const attr = get(data, 'raw.detail.combination.attributes', '');

        combination_attribute =
          get(data, 'combination_name', '') ||
          get(data, 'combination.name', '') ||
          (attr && Object.values[attr][0]) ||
          '';
      }

      try {
        state_data.product.detail.combination = {};
        if (state_data.product.detail.combinations && state_data.product.detail.combinations.length) {
          const get_the_combination = state_data.product.detail.combinations.filter((value) => {
            return value._id == state_data.product.combination_id;
          });
          if (get_the_combination.length && get_the_combination[0]) {
            state_data.product.detail.combination = get_the_combination[0];

            if (!data.dataCart) {
              default_price = get_the_combination[0].price_after_discount
                ? get_the_combination[0].price_after_discount
                : get_the_combination[0].price;
            }
          }
        } else if (state_data.product.detail.default_combination) {
          default_price = state_data.product.detail.default_combination.price_after_discount
            ? state_data.product.detail.default_combination.price_after_discount
            : state_data.product.detail.default_combination.price;
        }
      } catch {
        // do nothing
      }

      let cartData = {
        _id: state_data.product._id,
        qty: cart_qty,
        raw: state_data.product,
        name: state_data.product.detail.name,
        brand: state_data.product.detail.brand.name,
        price: default_price,
        combination_name: combination_attribute,
      };

      let productExist = '';
      for (let i = 0; i < state.footer_cart.length; i++) {
        if (state.footer_cart[i].raw.detail.vending_slot === state_data.product.slot) {
          productExist = i;
        }
      }

      const newData = [...state.footer_cart];

      if (productExist !== '') {
        if ('operator' in data) {
          if (data.operator === 'plus') {
            newData[productExist].qty = newData[productExist].qty + 1;
          } else if (data.operator === 'minus') {
            newData[productExist].qty = newData[productExist].qty - 1;
          }
        } else {
          newData[productExist].qty = newData[productExist].qty + 1;
        }

        if (data.pack_content) {
          for (const i in data.pack_content) {
            if (i in state.footer_cart[productExist].pack_content) {
              newData[productExist].pack_content[i].qty =
                state.footer_cart[productExist].pack_content[i].qty + data.pack_content[i].qty;
            } else {
              newData[productExist].pack_content[i] = data.pack_content[i];
            }
          }
        }

        commit('footer_cart', newData);
      } else {
        if (data.isEgift && !hasEgift) {
          cartData = [{ ...cartData }];
        }

        commit('footer_cart', cartData);
      }

      if ('nologin' in data && data.nologin) {
        const filtered_cart = state.footer_cart.filter((c) => c.qty > 0);

        await Promise.all([commit('footer_cart', filtered_cart), ls.set('cart', JSON.stringify(filtered_cart))]);
      }

      if (data.with_popup) {
        if (state.footer_cart.length) {
          const totalQty = state.footer_cart.reduce((prev, cur) => prev + Number(cur.qty), 0);
          commit('cart_qty', totalQty);
        } else {
          commit('cart_qty', state.cart_qty + cart_qty);
        }
      } else {
        if ('operator' in data) {
          const totalQty = state.footer_cart.reduce((prev, cur) => prev + Number(cur.qty), 0);
          commit('cart_qty', totalQty);
        } else {
          commit('cart_qty', state.cart_qty + 1);
        }
      }

      dispatch('updateCartPriceTotal', { cart: getters.cart_price });
    },
    setNoLoginCart({ commit, state, dispatch, getters }, data) {
      setTimeout(() => {
        const cart = JSON.parse(ls.get('cart'));
        commit(
          'cart_qty',
          (cart || []).reduce((sum, cur) => sum + Number(cur.qty), 0)
        );
        commit('footer_cart', cart);
        dispatch('updateCartPriceTotal', { cart: getters.cart_price });
        commit('dataOrder', { total_amount: state.cart_price_total.cart });
      }, 400);
    },
    addToCartNoLogin({ commit, state, dispatch, getters }, data) {
      commit('statusCart', true);
      let combination = '';
      let product = {};

      if (data.combination_name) {
        combination = data.combination_name;
      } else {
        if (data.dataModalCart) {
          combination = data.dataModalCart.attribute_html;
        }
      }

      if (data.product.detail) {
        product = data.product.detail;
      } else {
        product = data.product;
      }

      data.nologin = true;

      dispatch('setFooterCart', data);

      commit('SET_NOTIFICATION_ADD_CART_DATA', product);
      if (combination) {
        commit('SET_NOTIFICATION_ADD_CART_COMBINATION_GROUP_NAME', combination);
      }

      if (data.with_popup) {
        commit('SHOW_MODAL', 'notificationAddCart');
      }

      data.loadingBag = false;
      dispatch('updateCartPriceTotal', { cart: getters.cart_price });
      commit('dataOrder', { total_amount: state.cart_price_total.cart });

      setTimeout(() => {
        commit('statusCart', false);
      }, 1000);

      if (state.addCartType == 'buy') {
        commit('HIDE_MODAL', 'notificationAddCart');
        commit('SET_NOTIFICATION_ADD_CART_DATA', null);
        if (combination) {
          commit('SET_NOTIFICATION_ADD_CART_COMBINATION_GROUP_NAME', null);
        }
      } else {
        setTimeout(() => {
          commit('HIDE_MODAL', 'notificationAddCart');
          commit('SET_NOTIFICATION_ADD_CART_DATA', null);
          if (combination) {
            commit('SET_NOTIFICATION_ADD_CART_COMBINATION_GROUP_NAME', null);
          }
        }, 4000);
      }
      //state.cart_qty = state.footer_cart.length
    },
    fetchCurrentUserQtyCart({ commit }, context) {
      axios.get(`${Config.MS_CART_API_URL}/user/me/carts/count`).then((response) => {
        const count = (response.data && response.data.data) || 0;
        commit('cart_qty', count);

        // mark already fetched if total eq 0
        if (count === 0) {
          commit('cart_fetched');
          commit('cart_processing', false);
        }
      });
    },
    async fetchCurrentUserCart({ commit, state, dispatch, getters, rootState }, context) {
      commit('cart_error', false);
      dispatch('updateCartPriceTotal', { cart: getters.cart_price });
      // commit('dataOrder', { total_amount: state.cart_price_total.cart });

      if (typeof source !== 'undefined') {
        source.cancel(false);
      }

      CancelToken = axios.CancelToken;
      source = CancelToken.source();

      let token = await Cookies.get('token');
      if (token == undefined || !token) {
        token = rootState.auth.authToken;
      }

      const headers = {
        Authorization: `Bearer ${token}`,
        'SOC-PLATFORM': 'vending-machine',
      };

      const loc = await Cookies.get('store_location_id');
      if (loc) {
        headers.store_id = loc;
      }

      const params = {};
      const is_guest = await ls.get('is_guest');
      if (is_guest) {
        const is_guest_cart_id = await ls.get('guest_cart_id');
        Object.assign(params, {
          filter: {
            cart_id: is_guest_cart_id,
            is_guest: true,
          },
        });

        headers['is-guest'] = true;
        headers['guest-checkout'] = true;
      }

      return axios
        .get(`${Config.MS_CART_API_URL}/user/me/carts`, {
          cancelToken: source.token,
          params: params,
          headers: headers,
        })
        .then((response) => {
          if (response.status == 200 && '_id' in response.data.data) {
            // commit('dataOrder', response.data.data);

            dispatch('updateCartPriceTotal', { discount: 0 });

            // const newData = context.$get(response.data.data, 'products', []).map((item) => {
            //   const { quantity: qty } = item;
            //   let { _id } = item;

            //   if (!_id) {
            //     const findProduct = state.product_list.find((p) => p.id === item.id);
            //     if (findProduct) {
            //       _id = findProduct._id;
            //     }
            //   }

            //   const raw = Object.assign({}, item);
            //   const brand = context.$get(item, 'detail.brand.name', '');
            //   const name = context.$get(item, 'detail.name', '');

            //   return { _id, brand, raw, qty, name };
            // });

            commit('point_applied', context.$get(response.data.data, 'point_applied', 0));

            // dispatch('setFooterCart', newData);
            // commit('footer_cart', newData);
            commit(
              'cart_qty',
              state.footer_cart.reduce((sum, cur) => sum + Number(cur.qty), 0)
            );
            setTimeout(() => {
              commit('loadingCart', false);
            }, 2000);

            return true;
          } else {
            commit('loadingCart', false);
            return;
          }
        })
        .catch((error) => {
          commit('loadingCart', false);
          console.log(error);
          return;
        });
    },

    // direct update to ms-cart services.
    async updateCart({ commit, state, rootState, dispatch }, { product, index, context }) {
      const cart_id = rootState.QRcode?.latest_cart_id;
      if (cart_id) {
        product['cart_id'] = cart_id;
      }

      const headers = {
        'SOC-PLATFORM': 'vending-machine',
      };

      const is_guest = await ls.get('is_guest');

      if (is_guest) {
        headers['is-guest'] = true;
        headers['guest-checkout'] = true;
      }

      if (state.authToken) {
        headers['Authorization'] = `Bearer ${state.authToken}`;
      }

      return axios.post(`${Config.MS_CART_API_URL}/user/me/carts`, product, { headers: headers }).then(async () => {
        await commit('updateCartQuantity', { index, action: product.action });
        return dispatch('fetchCurrentUserCart', context);
      });
    },

    updateCartPriceTotal({ commit, state, dispatch }, data) {
      const newCartPriceTotal = Object.assign({}, state.cart_price_total, data);
      commit('cart_price_total', newCartPriceTotal);
    },
    resetCart({ commit, state, dispatch }, data) {
      commit('footer_cart', []);
      commit('cart_qty', Number(data));
    },

    async applyPoints({ state, dispatch }, { token, point, cartId, context }) {
      if (typeof source !== 'undefined') {
        source.cancel(false);
      }

      CancelToken = axios.CancelToken;
      source = CancelToken.source();

      const store_id = await Cookies.get('store_location_id');

      const headers = {
        'SOC-PLATFORM': 'vending-machine',
        store_id: store_id,
      };

      if (token) {
        headers['Authorization'] = `Bearer ${token}`;
      } else if (state.authToken) {
        headers['Authorization'] = `Bearer ${state.authToken}`;
      }

      return axios
        .post(
          `${Config.MS_CART_API_URL}/carts/points`,
          {
            points: point,
            cartId: cartId,
          },
          {
            cancelToken: source.token,
            headers: headers,
          }
        )
        .then((res) => {
          if (res.data.success) {
            return true;
          }
        })
        .catch((err) => {
          return err;
        });
    },
    cancelPoints({ state, dispatch }, { token, points, cart_id, context }) {
      const headers = {
        'SOC-PLATFORM': 'vending-machine',
      };

      if (token) {
        headers['Authorization'] = `Bearer ${token}`;
      } else if (state.authToken) {
        headers['Authorization'] = `Bearer ${state.authToken}`;
      }

      return axios
        .delete(`${Config.MS_CART_API_URL}/carts/points`, {
          data: {
            points: points,
            _id: cart_id,
          },
          headers: headers,
        })
        .then(async (res) => {
          if (res.data.success) {
            await dispatch('fetchCurrentUserCart', context);
            return true;
          }
        })
        .catch(() => {
          return false;
        });
    },
  },
};
