const Config = require('~/config/default.env').default;
import VUEX_HELPER from './vuex-helper/product-detail-helper';
import axios from 'axios';
export default {
  state: {
    data: {
      activeAttribute: '',
      attributesImage: '',
      attributesProduct: '',
      isAttributeNotChange: '',
      product: '',

      productImages: '',
      relatedContent: '',
      reviews: '',
      totalReview: '',
      couple: [],
      firstmatch: '',
      currentCouple: [],
      selectedImage: '',
      selectedImageList: [],
      selectedPrice: '',
      selectedAttribute: '',
    },
    isLoadingFinish: {
      product: false,
      relatedContent: false,
      reviews: false,
    },
  },
  mutations: {
    selectedImage(state, data) {
      state.data.selectedImage = data.image;
    },
    selectedImageList(state, data) {
      state.data.selectedImageList = data;
    },
    selectedPrice(state, data) {
      state.data.selectedPrice = data;
    },
    selectedAttribute(state, data) {
      state.data.selectedAttribute = data;
    },
    currentCouple(state, data) {
      state.data.currentCouple = data;
    },
    setTotalReview(state, totalReview) {
      state.data.totalReview = totalReview;
    },
    setLoadingApiOnProductDetail(state, { stateName, newVal }) {
      state.isLoadingFinish[stateName] = newVal;
    },
    setDataOnProductDetail(state, { stateName, newVal }) {
      state.data[stateName] = newVal;
    },
    setDefaultAttribute(state, createdArrAttributes) {
      const defaultAttr = {};

      if (this._vm.$SO.size(state.data.product.default_product_attribute)) {
        this._vm.$SO.each(state.data.product.default_product_attribute.attribute, (key, obj) => {
          defaultAttr[obj.attribute_group_name] = obj.id_attribute;

          this._vm.$SO.each(createdArrAttributes, (key, objAttr) => {
            if (obj.attribute_group_name in objAttr) {
              this._vm.$SO.each(objAttr[obj.attribute_group_name].data, (keyData, data) => {
                if (defaultAttr[obj.attribute_group_name] === data.id) {
                  // createdArrAttributes[key][obj.attribute_group_name].data[keyData].isActive = true
                }
              });
            }
          });
        });
      }

      state.data.activeAttribute = defaultAttr;
      state.data.attributesProduct = createdArrAttributes;
    },
    updateAttributeActive(
      state,
      { attribute_group_name, id_attribute, keyAttributes, keyData, galleryThumbs, galleryTop, thisel }
    ) {
      this._vm.$SO.each(state.data.activeAttribute, (prop, val) => {
        this._vm.$SO.each(state.data.attributesProduct, (key, objAttr) => {
          if (attribute_group_name in objAttr) {
            this._vm.$SO.each(objAttr[attribute_group_name].data, (keyData, data) => {
              if (state.data.activeAttribute[attribute_group_name] === data.id) {
                // state.data.attributesProduct[key][attribute_group_name].data[keyData].isActive = false
              }
            });
          }
        });
      });

      // state.data.attributesProduct[keyAttributes][attribute_group_name].data[keyData].isActive = true
      state.data.activeAttribute[attribute_group_name] = id_attribute;

      if (state.data.attributesProduct.length > 1) {
        const dataProductAttr = state.data.product.product_attribute;
        const couple = [];
        for (const i in dataProductAttr) {
          const attr = dataProductAttr[i].attribute;
          let checkattrtemp = '';
          let checkexist = false;
          for (const j in attr) {
            checkattrtemp = dataProductAttr[i];
            if (attr[j].id_attribute == id_attribute) {
              checkexist = true;
            }
          }
          if (checkexist) {
            couple.push(checkattrtemp);
          }
          checkattrtemp = '';
        }

        const couplefix = [];
        // const selectedAttribute = '';
        let selectedImage = '';
        let selectedPrice = '';

        if (couple.length) {
          for (const i in couple) {
            for (const j in couple[i].attribute) {
              if (
                couple.indexOf(couple[i].attribute[j].id_attribute) < 0 &&
                couple[i].attribute[j].id_attribute != id_attribute
              ) {
                couplefix.push(couple[i].attribute[j].id_attribute);
                if (!selectedImage) {
                  selectedImage = couple[i].images[0].url;
                  state.data.selectedImage = selectedImage;
                  state.data.selectedImageList = couple[i].images;
                  try {
                    document
                      .getElementsByClassName('swiper-slide-thumb-active')[0]
                      .classList.remove('swiper-slide-thumb-active');
                  } catch (err) {}
                }
                if (!selectedPrice) {
                  selectedPrice = couple[i].price;
                  state.data.selectedPrice = selectedPrice;
                }
                state.data.selectedAttribute = couple[i];
                thisel.offlineStoreStock = couple[i].offline_stock;
              }
            }
          }
        }

        // console.log(couplefix)
        state.data.couple = couplefix;
        state.data.firstmatch = couplefix[0];

        const currentCouple = [];
        currentCouple.push(id_attribute, couplefix[0]);
        state.data.currentCouple = currentCouple;
      } else {
        state.data.selectedImage = state.data.attributesImage[id_attribute].length
          ? state.data.attributesImage[id_attribute][0].url
          : '/static/img/mask-station/default-image-product.png';
        state.data.selectedImageList = state.data.attributesImage[id_attribute];
        state.data.couple = id_attribute;
        state.data.currentCouple = id_attribute;
        try {
          document.getElementsByClassName('swiper-slide-thumb-active')[0].classList.remove('swiper-slide-thumb-active');
        } catch (err) {}
        const dataProductAttr = state.data.product.product_attribute;
        for (const i in dataProductAttr) {
          const attr = dataProductAttr[i].attribute;
          for (const j in attr) {
            if (attr[j].id_attribute == id_attribute) {
              state.data.selectedPrice = dataProductAttr[i].price;
              thisel.offlineStoreStock = dataProductAttr[i].offline_stock;
              state.data.selectedAttribute = dataProductAttr[i];
              break;
            }
          }
        }
      }

      setTimeout(function () {
        galleryThumbs.update();
        galleryTop.update();
      }, 400);
    },
  },
  actions: {
    generatedArrayProductAttribute({ state, commit, dispatch }) {
      let createdArrAttributes = [];
      const createdObjAttributesImage = {};
      const createdProductImages = [];

      this._vm.$SO.each(state.data.product.product_attribute, (key, attributes) => {
        let attributeIdMerge = '';
        this._vm.$SO.each(attributes.attribute, (keyAttributes, attribute) => {
          attributeIdMerge += attribute.id_attribute;
          if (
            this._vm.$SO.size(createdArrAttributes) &&
            VUEX_HELPER.checkingAttributeExist(createdArrAttributes, attribute.attribute_group_name)
          ) {
            createdArrAttributes = VUEX_HELPER.appendAttributesProduct(createdArrAttributes, {
              attribute_group_name: attribute.attribute_group_name,
              attribute_name: attribute.attribute_name,
              id_attribute: attribute.id_attribute,
            });
          } else {
            const obj = {};
            obj[attribute.attribute_group_name] = {
              data: [{ name: attribute.attribute_name, id: attribute.id_attribute, isActive: false, isCouple: false }],
            };
            createdArrAttributes.push(obj);
          }
        });

        createdObjAttributesImage[attributeIdMerge] = attributes.images;
      });

      commit('setDefaultAttribute', createdArrAttributes);
      commit('setDataOnProductDetail', { stateName: 'productImages', newVal: createdProductImages });
      commit('setDataOnProductDetail', { stateName: 'attributesImage', newVal: createdObjAttributesImage });
    },
    getProductDetail({ commit }, { productId, pageName }) {
      commit('setLoadingApiOnProductDetail', { stateName: 'product', newVal: false });
      // axios.defaults.withCredentials = false;
      let configUrl = '';
      if (pageName === 'lipstick') {
        configUrl = `${Config.MS_SOCO_PUBLIC_API_URL}/products/${productId}`;
      } else {
        configUrl = `${Config.MS_SOCO_PUBLIC_API_URL}/products/sociolla/${productId}`;
      }
      const headers = {
        'SOC-PLATFORM': 'sociolla-pos',
      };
      let params = {
        params: {
          id_store:
            Cookies.get('store_location_id') && Cookies.get('store_location_id') != undefined
              ? Cookies.get('store_location_id')
              : Config.store_id,
        },
      };
      if (pageName === 'lipstick') {
        params = { headers };
      }
      axios
        .get(configUrl, params)
        .then((response) => {
          axios.defaults.withCredentials = true;
          if (response.status === 200) {
            response.data.data.isSeeMoreActive = false;
            commit('setDataOnProductDetail', { stateName: 'product', newVal: response.data.data });
          } else {
            commit('setDataOnProductDetail', { stateName: 'product', newVal: {} });
          }
          commit('setLoadingApiOnProductDetail', { stateName: 'product', newVal: true });
        })
        .catch((error) => {
          commit('setDataOnProductDetail', { stateName: 'product', newVal: {} });
          commit('setLoadingApiOnProductDetail', { stateName: 'product', newVal: true });
        });
    },
    getRecomendedReview({ commit }, productId) {
      return axios.get(`${Config.MS_SOCO_PUBLIC_API_URL}/products/${productId}`);
    },
    getRelatedContent({ commit }) {
      commit('setLoadingApiOnProductDetail', { stateName: 'relatedContent', newVal: false });
      axios
        .get(`${Config.MS_SOCO_PUBLIC_API_URL}/newsfeeds/recommend-content/user`, {
          params: {
            filter: {
              types: ['article'],
            },
          },
        })
        .then((response) => {
          const modifyDataStructure = [];
          let tempArr = [];
          let totalData;

          if (response.status === 200) {
            totalData = this._vm.$SO.size(response.data.data);
            this._vm.$SO.each(response.data.data, (key, data) => {
              tempArr.push(data);
              if (this._vm.$SO.size(tempArr) === 2) {
                modifyDataStructure.push(tempArr);
                tempArr = [];
              }

              if (totalData % 2 !== 0 && key + 1 === totalData) {
                modifyDataStructure.push(tempArr);
              }
            });
            commit('setDataOnProductDetail', { stateName: 'relatedContent', newVal: modifyDataStructure });
          } else {
            commit('setDataOnProductDetail', { stateName: 'relatedContent', newVal: [] });
          }
          commit('setLoadingApiOnProductDetail', { stateName: 'relatedContent', newVal: true });
        })
        .catch((error) => {
          commit('setLoadingApiOnProductDetail', { stateName: 'relatedContent', newVal: true });
          commit('setDataOnProductDetail', { stateName: 'relatedContent', newVal: [] });
        });
    },
    getReviewsProduct({ commit, state }) {
      commit('setLoadingApiOnProductDetail', { stateName: 'reviews', newVal: false });
      axios
        .get(`${Config.MS_SOCO_PUBLIC_API_URL}/products/reviews/${state.data.product.id_product_soco}`, {
          params: {
            per_page: 10,
          },
        })
        .then((response) => {
          const modifyDataStructure = [];
          let tempArr = [];
          let totalData;

          if (response.status === 200) {
            totalData = this._vm.$SO.size(response.data.data);
            this._vm.$SO.each(response.data.data, (key, data) => {
              const createdRatingTypes = [];
              this._vm.$SO.each(data, (prop, value) => {
                const newObjRating = {};
                if (/star_/.test(prop) && !/star_average/.test(prop) && value) {
                  newObjRating.name = prop;
                  newObjRating.value = value;
                  createdRatingTypes.push(newObjRating);
                }
              });

              data.ratingTypes = createdRatingTypes;
              data.isSeeMoreActive = false;
              tempArr.push(data);
              if (this._vm.$SO.size(tempArr) === 2) {
                modifyDataStructure.push(tempArr);
                tempArr = [];
              }

              if (totalData % 2 !== 0 && key + 1 === totalData) {
                modifyDataStructure.push(tempArr);
              }
            });
            commit('setDataOnProductDetail', { stateName: 'reviews', newVal: modifyDataStructure });
          } else {
            commit('setDataOnProductDetail', { stateName: 'reviews', newVal: [] });
          }
          commit('setLoadingApiOnProductDetail', { stateName: 'reviews', newVal: true });
        })
        .catch((error) => {
          commit('setDataOnProductDetail', { stateName: 'reviews', newVal: [] });
          commit('setLoadingApiOnProductDetail', { stateName: 'reviews', newVal: true });
        });
    },
    getReviewsProductV2({ commit, state }) {
      if (!state.data.product.id) {
        return false;
      }
      commit('setLoadingApiOnProductDetail', { stateName: 'reviews', newVal: false });
      axios
        .get(`${Config.MS_SOCO_PUBLIC_API_URL}/product/${state.data.product.id}/reviews`, {
          params: {
            // per_page: 10
            sort: '-created_at',
            skip: 0,
            limit: 10,
          },
        })
        .then((response) => {
          const modifyDataStructure = [];
          let tempArr = [];
          let totalData;

          if (response.status === 200) {
            totalData = this._vm.$SO.size(response.data.data);
            this._vm.$SO.each(response.data.data, (key, data) => {
              const createdRatingTypes = [];
              const allrating = [];
              let ratingsum = 0;
              this._vm.$SO.each(data, (prop, value) => {
                const newObjRating = {};
                if (/star_/.test(prop) && !/star_average/.test(prop) && value) {
                  newObjRating.name = prop;
                  newObjRating.value = value;
                  allrating.push(value);
                  ratingsum = ratingsum + Number(value);
                  createdRatingTypes.push(newObjRating);
                }
              });

              data.ratingTypes = createdRatingTypes;
              data.isSeeMoreActive = false;

              data.counter_star = ratingsum / allrating.length;

              tempArr.push(data);
              if (this._vm.$SO.size(tempArr) === 2) {
                modifyDataStructure.push(tempArr);
                tempArr = [];
              }

              if (totalData % 2 !== 0 && key + 1 === totalData) {
                modifyDataStructure.push(tempArr);
              }
            });
            commit('setDataOnProductDetail', { stateName: 'reviews', newVal: modifyDataStructure });
          } else {
            commit('setDataOnProductDetail', { stateName: 'reviews', newVal: [] });
          }
          commit('setLoadingApiOnProductDetail', { stateName: 'reviews', newVal: true });
        })
        .catch((error) => {
          commit('setDataOnProductDetail', { stateName: 'reviews', newVal: [] });
          commit('setLoadingApiOnProductDetail', { stateName: 'reviews', newVal: true });
        });
    },
  },
  getters: {},
};
