export default {
  state: {
    productsVm: [],
    urlVm: '',
    selectedProductVm: '',
  },
  mutations: {
    setProductsVm(state, payload) {
      state.productsVm = payload;
    },
    setUrlVm(state, payload) {
      state.urlVm = payload;
    },
    setSelectedProductVm(state, payload) {
      state.selectedProductVm = payload;
    },
  },
};
