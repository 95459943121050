const Config = require('~/config/default.env').default;

export default {
  state: {
    banners: {
      idle: null,
      pdp: null,
    },
    product_detail: null,
    bundle_product: null,
    loading_product_detail: true,
    idle_check: 'initiate',
  },
  mutations: {
    SET_IDLE_CHECK(state, value) {
      state.idle_check = value;
    },
    SET_BANNER(state, { type, value }) {
      state.banners[type] = value;
    },
    SET_PRODUCT_DETAIL(state, value) {
      state.product_detail = value;
    },
    SET_LOADING_PRODUCT_DETAIL(state, value) {
      state.loading_product_detail = value;
    },
    SET_BUNDLE_PRODUCT(state, value) {
      state.bundle_product = value;
    },
  },
  actions: {
    async setIdleCheck({ commit }, value) {
      commit('SET_IDLE_CHECK', value);
    },
    async getProductDetail({ commit }, { barcode }) {
      if (!barcode || barcode.length === 0) {
        return null;
      }
      commit('SET_PRODUCT_DETAIL', null);
      commit('SET_BUNDLE_PRODUCT', null);
      commit('SET_LOADING_PRODUCT_DETAIL', true);
      const loc = await Cookies.get('store_location_id');
      return axios({
        url: `${Config.MS_CATALOG_API_URL}/v3/products`,
        method: 'get',
        headers: {
          store_id: loc,
          'SOC-PLATFORM': 'sociolla_store',
          'soc-source': 'sociolla_store',
        },
        params: {
          filter: {
            'combinations.ean_no': barcode,
            is_active_in_offline_store: true,
            price_checker: true,
          },
        },
      })
        .then((res) => {
          if (res && res.data && res.data.data && res.data.data.length > 0) {
            commit('SET_PRODUCT_DETAIL', res.data.data[0]);
            const productId = res.data.data[0].id;
            axios({
              url: `${Config.MS_CATALOG_API_URL}/v3/products/${productId}/bundles`,
              method: 'get',
              headers: {
                store_id: loc,
                'SOC-PLATFORM': 'offline_store',
                'soc-source': 'offline_store',
              },
              params: {
                skip: 0,
                limit: 3,
              },
            }).then((res) => {
              if (res.data.data && res.data.data.length > 0) {
                commit('SET_BUNDLE_PRODUCT', res.data.data);
              }
            });
          } else {
            commit('SET_PRODUCT_DETAIL', null);
            commit('SET_BUNDLE_PRODUCT', null);
          }
        })
        .catch((err) => {
          console.log(err);
          commit('SET_PRODUCT_DETAIL', null);
          commit('SET_BUNDLE_PRODUCT', null);
        })
        .finally(() => {
          commit('SET_LOADING_PRODUCT_DETAIL', false);
        });
    },
    async getDandelionStoreBanner({ commit }, location) {
      if (!location) {
        return null;
      }

      const loc = await Cookies.get('store_location_id');

      return axios
        .get(`${Config.MS_CATALOG_API_URL}/banners/all`, {
          params: {
            filter: {
              locations: [location],
              is_active_in_offline_store: true,
              is_active: true,
              is_deleted: false,
              start_date: { $lte: new Date() },
              end_date: { $gte: new Date() },
            },
            sort: '-created_at',
          },
        })
        .then((response) => {
          if (response && response.data && response.data.data && location in response.data.data) {
            let selected_banner = null;
            let banners = response.data.data[location];
            banners = banners.sort((a, b) => a.position - b.position);

            for (let i = 0; i < banners.length; i++) {
              const banner = banners[i];
              const findStore = banner.stores.find((s) => s.id === loc);

              if (!findStore) {
                continue;
              }

              selected_banner = banner;
              break;
            }

            if (selected_banner) {
              commit('SET_BANNER', {
                type: location === 'store_admin_price_checker_idle' ? 'idle' : 'pdp',
                value: selected_banner.images[0].url,
              });
            }
          }
        });
    },
  },
};
