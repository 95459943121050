export default {
  state: {
    zits: '',
    start_time: null,
  },
  mutations: {
    setStartTime(state, time) {
      state.start_time = time;
    },
    setZits(state, zits) {
      state.zits = zits;
    },
  },
};
