export default {
  methods: {
    hummanizeMessage(str) {
      if (str == 'EMAIL_USERNAME_EXISTS') {
        return 'Email atau username telah terdaftar';
      } else {
        const message = str.split('_').join(' ').toLowerCase();
        const firstLetter = message.charAt(0).toUpperCase();
        const noFirstletter = message.slice(1);
        return firstLetter + noFirstletter;
      }
    },
  },
};
