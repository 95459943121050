import Axios from 'axios';
import store from '../store';
import router from '../router';

const Config = require('~/config/default.env').default;

export default function setup(Vue) {
  Axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const is_vmcola = await Cookies.get('location_detail_vm_cola');
      const path = router.app.$route.path;
      const conf_values = Object.values(Config) || [];
      const idx = conf_values.findIndex((c) => typeof c === 'string' && error.config.url.indexOf(c) > -1);
      const service = conf_values[idx];
      const endpoint = (error?.config?.url && error.config.url.replace(service, '')) || null;

      if (endpoint.indexOf('/trigger') > -1 || endpoint.indexOf('/bucket') > -1) {
        return Promise.reject(error);
      }

      if (
        is_vmcola &&
        path !== '/' &&
        !['/users/me/orders', '/payments/callback', '/order', 'banners/all', '/banners/all'].includes(endpoint)
      ) {
        const errData = error.response && error.response.data && error.response.data;

        if (
          ['ERR_ACCOUNT_AGE_NOT_ENOUGH', 'ERR_ACCOUNT_ZERO_ORDER', 'POINTS_NOT_VALID', 'UNVERIFIED_PHONE'].includes(
            errData?.errorCode
          )
        ) {
          if (errData?.errorCode === 'UNVERIFIED_PHONE') {
            Vue.toasted.global.error('Please verified your phone number first!');
          } else {
            Vue.toasted.global.error(errData?.message || 'Something went wrong!');
          }
          return Promise.reject(error);
        }

        const promises = [
          localStorage.removeItem('guest_cart_id'),
          localStorage.removeItem('latest_cart_id'),
          localStorage.removeItem('is_guest'),
          localStorage.removeItem('cart'),
          store.commit('SET_CART_ID', null),
          store.commit('SET_SELECTED_POINT', 0),
          store.commit('SET_ALREADY_SEND_CART', false),
          store.commit('footer_cart', []),
          store.commit('cart_qty', 0),
          store.dispatch('resetCart', 0),
        ];

        if (store.state?.QRcode?.authToken) {
          promises.push(store.dispatch('destroyQrSession'));
        }

        await Promise.all(promises).finally(() => {
          Vue.toasted.global.error('Something went wrong!');
          router.app.$router.push('/vmcola');

          setTimeout(() => {
            location.reload();
          }, 5000);
        });
      }

      return Promise.reject(error);
    }
  );
}
