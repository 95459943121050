export default {
  state: {
    background: '',
  },
  mutations: {
    setBackgroundCheckinStation(state, data) {
      state.background = data;
    },
  },
  actions: {},
  getters: {},
};
