import router from '@/router';
const Config = require('~/config/default.env').default;

export default {
  state: {
    loggedin: false,
    userProfile: null,
    processingLogout: false,
    userSkintypes: [],
    intervalCheckingLogin: '',
    authToken: '',
    voucherCode: '',

    userPointList: [],
  },
  mutations: {
    loggedIn(state, payload) {
      state.loggedin = payload;
    },
    setProfile(state, payload) {
      state.userProfile = payload;

      //set user skin types
      if (payload && payload.beauty) {
        let output = [];
        const jeniskulit = payload.beauty
          .filter(
            (t) =>
              t.name == 'jenis-kulit' ||
              t.name == 'kondisi-kulit' ||
              t.name == 'jenis-rambut' ||
              t.name == 'kondisi-rambut'
          )
          .map((t, i) => {
            if (t.name == 'jenis-kulit') {
              t.position = 1;
            } else if (t.name == 'kondisi-kulit') {
              t.position = 2;
            } else {
              t.position = 3;
            }
            return t;
          });
        jeniskulit.sort((a, b) => {
          if (a.position < b.position) {
            return -1;
          }
          if (a.position < b.position) {
            return 1;
          }
          return 0;
        });
        for (const i in jeniskulit) {
          const newarray = jeniskulit[i].subtags.slice(0);
          output = output.concat(newarray);
        }
        state.userSkintypes = output;
      }
    },
    authToken(state, payload) {
      state.authToken = payload;
    },
    voucherCode(state, payload) {
      state.voucherCode = payload;
    },
    setProcessingLogout(state, newVal) {
      state.processingLogout = newVal;
    },
    setIntervalCheckingLogin(state, newVal) {
      state.intervalCheckingLogin = newVal;
    },
    setUserPointList(state, data) {
      state.userPointList = data;
    },
  },
  actions: {
    checkLogin({ commit, dispatch, state }, options) {
      if (options.token) {
        axios
          .get(`${Config.MS_SOCO_PUBLIC_API_URL}user/me`, {
            headers: {
              Authorization: `Bearer ${options.token}`,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              commit('setProfile', response.data.data);
            } else {
              commit('setProfile', {});
              commit('setStateQRCode', { stateName: 'error', newVal: true });
            }

            clearInterval(state.intervalCheckingLogin);
          })
          .catch((error) => {
            clearInterval(state.intervalCheckingLogin);
          });
      } else {
        dispatch('userLogout', options.home);
      }
    },
    me({ dispatch, commit }) {
      return axios
        .get(Config.MS_SOCO_PUBLIC_API_URL + '/user/me', {
          headers: {
            Authorization: `Bearer ${
              Cookies.get('token') && Cookies.get('token') != undefined ? Cookies.get('token') : ''
            }`,
          },
        })
        .then((response) => {
          if (response.data.success) {
            commit('setProfile', response.data.data);
            commit('loggedIn', response.data.data);
            return true;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    setProfile(state, payload) {
      state.commit('setProfile', payload);
    },
    saveStoreIdCookies({ state, commit, dispatch }, storeId) {
      if (storeId) {
        Cookies.set('store_location_id', storeId, { expires: 1000 });
      } else {
        if (Cookies.get('store_location_id') == undefined) {
          Cookies.set('store_location_id', Config.store_id, { expires: 1000 });
        }
      }
    },
    vmColaLocationDetailCookies({ state, commit, dispatch }, locDetail) {
      const vmId = locDetail && locDetail._id ? locDetail._id : '';
      Cookies.set('location_detail_vm_cola', vmId, { expires: 1000 });
    },
    VendingMachineDevice(context, data) {
      Cookies.set('vending_machine_device', data, { expires: 1000 });
    },
    saveStoreDetail({ state, commit, dispatch }, loc) {
      Cookies.set('store_detail', JSON.stringify(loc), { expires: 1000 });
    },
    saveStoreList({ state, commit, dispatch }, store_list) {
      Cookies.set('store_list', JSON.stringify(store_list), { expires: 1000 });
    },
    getProfile(state) {
      if (!state.userProfile) {
        axios.get(`${Config.MS_SOCO_PUBLIC_API_URL}/user/me`).then((res) => {
          if (res.data.success) {
            const data = res.data.data;
            state.commit('setProfile', data);
          }
        });
      }
    },
    login({ state, commit }, data) {
      axios
        .post(Config.MS_SOCO_PUBLIC_API_URL + '/auth/login', {
          email: data.username,
          password: data.password,
        })
        .then((response) => {
          if (response.status == '200') {
            commit('setProfile', response.data.data.user);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    logout({ state, commit }, home) {
      if (state.userProfile) {
        axios
          .get(`${Config.MS_SOCO_PUBLIC_API_URL}/auth/logout`)
          .then((res) => {
            if (res.data.success) {
              commit('setProfile', '');
              commit('authToken', '');
              this._vm.$toasted.global.show('Logout berhasil!');
              commit('setProcessingLogout', false);
              Cookies.remove('expired_token');
              router.push(home || '/');
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        router.push(home || '/');
      }
    },
    userLogout({ state, commit }, home) {
      commit('setProcessingLogout', true);
      axios
        .get(Config.MS_SOCO_PUBLIC_API_URL + '/auth/logout')
        .then((response) => {
          if (response.status === 200) {
            commit('setProfile', '');
            commit('authToken', '');
            this._vm.$toasted.global.show('Logout berhasil!');
            router.push({ name: home });
            commit('setProcessingLogout', false);
            Cookies.remove('expired_token');
          } else {
            this._vm.showLoader = false;
            this._vm.$toasted.global.error('Silakan coba lagi dalam beberapa saat.');
          }
        })
        .catch((error) => {
          console.error(error);
          this._vm.$toasted.global.error('Silakan coba lagi dalam beberapa saat.');
          commit('setProcessingLogout', false);
        });
    },

    getPointListByUser({ state, commit }, { total_amount }) {
      const headers = {
        'SOC-PLATFORM': 'vending-machine',
      };

      if (state.authToken) {
        headers['Authorization'] = `Bearer ${state.authToken}`;
      }

      return axios
        .get(`${Config.MS_CART_API_URL}/points`, {
          params: {
            filter: {
              total_amount,
              is_new_ui: true,
            },
          },
          headers: headers,
        })
        .then((res) => {
          if (res?.data?.data?.length) {
            const points = (res.data.data || []).filter((sp) => sp.is_active).sort((a, b) => a.points - b.points);

            commit('setUserPointList', points);
            return points;
          }

          commit('setUserPointList', []);
          return [];
        })
        .catch((err) => {
          console.error(err);
          commit('setUserPointList', []);
        });
    },
  },
  getters: {
    full_name: (state) => {
      if (state.userProfile && state.userProfile.first_name && state.userProfile.last_name) {
        state.userProfile.first_name + ' ' + state.userProfile.last_name;
      } else {
        return '';
      }
    },
  },
};
