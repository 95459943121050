import Vue from 'vue';

const moment = require('moment');
// moment.locale('id');

Vue.filter('generatedArticleUrl', function (item) {
  let link = '';
  let slug = 'default';
  let category = 'default';

  let newsfeed_id = item._id;
  if (item.verb == 'reshare') {
    newsfeed_id = item._id;
  }

  if (typeof item.article.category != 'undefined' && item.article.category.length) {
    category = item.article.category[0].slug;
  }
  if (item.article && item.article.post_name) {
    slug = item.article.post_name;
  }
  link = '/post/' + category + '/' + newsfeed_id + '/' + slug + '/';
  return link;
});

Vue.filter('generatedVideoUrl', function (item) {
  let link = '';
  let slug = 'default';
  let newsfeed_id = item._id;

  if (item.verb == 'reshare') {
    newsfeed_id = item._id;
  }

  if (item.video.post_name) {
    slug = item.video.post_name;
  }

  link = '/bjtv/' + newsfeed_id + '/' + slug + '/';
  return link;
});

Vue.filter('generatedRatingTypeName', function (value) {
  const nameToLowerCase = value.toLowerCase();
  const removeWordIsStar = nameToLowerCase.replace('star_', '');
  const removeDash = removeWordIsStar.replace(/[_]/gi, ' ');
  return removeDash;
});

Vue.filter('dMyyyy', function (value) {
  return moment(value).format('LL');
});

Vue.filter('dateFormat', function (value) {
  return moment(value).format('DD MMMM YYYY');
});

Vue.filter('isHex', function (value) {
  if (value) {
    return /^#[0-9A-F]{6}$/i.test(value);
  }
  return false;
});

function ucwords(value) {
  return (value + '').toLowerCase().replace(/^(.)|\s+(.)/g, function ($1) {
    return $1.toUpperCase();
  });
}

Vue.filter('ucwords', ucwords);

Vue.filter('ucwordsAttributeName', function (value) {
  const newValue = ucwords(value);
  return newValue.replace(/\bMl\b/g, 'ml').replace(/\bGr\b/g, 'gr');
});

Vue.filter('rating', function (value) {
  return Number(value).toFixed(1) * 10;
});

Vue.filter('newRating', function (value) {
  const rating = Number(value.toFixed(1));

  return isNaN(rating) ? 0 : rating;
});

Vue.filter('productrating', function (value) {
  return Number(value.toFixed(1));
});

Vue.filter('currency', function (value) {
  value = parseInt(value);
  const res = value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');

  return res === '0' ? '0' : res;
});

Vue.filter('currencyRp', function (value) {
  value = parseInt(value);
  const res = (value || 0).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.');

  return res === '0' ? 'Rp 0' : `Rp ${res}`;
});

Vue.filter('shortenString', function (value) {
  if (typeof value === 'string') {
    // const maxLength = 282;
    const ellipsis = '...';
    let limitStr;

    if (value.length > 282) {
      limitStr = value.substring(0, 282) + ellipsis;
    } else {
      limitStr = value;
    }

    return limitStr;
  }

  return null;
});

Vue.filter('nFormatter', function (item) {
  if (!item) {
    return 0;
  }
  if (item >= 1000000000) {
    item = (item / 1000000000).toFixed(1).replace(/\.0$/, '') + 'g';
    item = item.replace('.', ',');
  } else if (item >= 1000000) {
    item = (item / 1000000).toFixed(1).replace(/\.0$/, '') + 'm';
    item = item.replace('.', ',');
  } else if (item >= 1000) {
    item = (item / 1000).toFixed(1).replace(/\.0$/, '') + 'k';
    item = item.replace('.', ',');
  }
  return item;
});
