<template>
  <transition v-if="isModalShow" name="modal">
    <div class="modal no-location" @keyup.esc="noAction()">
      <div class="overlay clickable" @click="noAction()"></div>
      <div class="modal-container">
        <div class="content">
          <h1>No location selected, please choose location:</h1>
          <select v-model="location">
            <option value disabled selected>Select Location</option>
            <option v-for="(item, key) in locationoption" :key="key" :value="item.id">{{ item.value }}</option>
          </select>
          <button :disabled="!location" type="submit" class="submit btn button" @click.prevent="setLocation()">
            SUBMIT
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'modalCheckLocation',
  data() {
    return {
      isModalShow: false,
      // locationoption: [],
      location: null,
      store_fetched: false,
    };
  },
  computed: {
    locationoption: {
      get() {
        return this.$store.state.stores;
      },
      set(val) {
        return val;
      },
    },
  },
  mounted() {
    if (Cookies.get('store_list') && Cookies.get('store_list') != undefined) {
      try {
        const json_data = JSON.parse(Cookies.get('store_list'));
        this.locationoption = json_data.name;
      } catch (err) {}
    } else {
      this.$store.dispatch('getPhysicalStores');
    }
    // this.locationoption = Config.store_lists;
  },
  created() {
    setTimeout(() => {
      this.checkLocation();
    }, 1000);
  },
  methods: {
    noAction() {
      javascript: void 0;
    },
    setLocation() {
      this.$store.dispatch('saveStoreIdCookies', this.location);
      this.closeModal();
      setTimeout(
        function () {
          location.reload();
        }.bind(this),
        400
      );
    },
    closeModal() {
      this.isModalShow = false;
    },
    showModal() {
      this.isModalShow = true;
    },
    checkLocation() {
      if (this.$route.path != '/') {
        const loc = Cookies.get('store_location_id');
        this.isModalShow = !loc || loc == undefined ? true : false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  .overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .modal-container {
    max-width: 578px;
    width: 100%;
    margin: 0 auto;
    overflow-y: auto;
    background: #fff;
    padding: 40px 32px;
    height: auto;
    border-radius: 2px;
    position: relative;
    z-index: 3;
    max-height: 729px;
  }
  .content {
    margin: 0 !important;
  }
  select {
    border-radius: 7px;
    height: 90px;
    font: normal 38px/47 'Lato-Regular', sans-serif;
    color: #8c8582;
    padding: 25px 88px 25px 30px;
    box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.08);
    width: 100%;
    position: relative;
  }
  .btn.submit {
    background: #b32656 !important;
    height: 93px;
    width: 100%;
    font: normal 32px/44px 'brandontext-bold', sans-serif;
    letter-spacing: 3.2px;
    margin: 63px 0 0;
    border-radius: 7px;
  }
  h1 {
    font: normal 38px/40px 'brandontext-bold', sans-serif;
    margin: 0 0 63px;
    text-align: center;
  }
}
</style>
