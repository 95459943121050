import axios from 'axios';
import _ from 'underscore';
import { get } from 'lodash';
import ls from '@/components/helpers/local-storage';

const config = require('~/config/default.env').default;
window.axios = axios;
axios.defaults.withCredentials = true;

/**
 * Generates a random alphanumeric string
 * @param {number} [length] length of string
 */
function randomString(length = 24) {
  return _.times(length, () => _.random(35).toString(36)).join('');
}

export default {
  state: {
    isLoggedIn: false,
    isLoggingOut: false,
    value: null,
    createdAt: null,
    user: null,
    token: null,
    authToken: null,
    login_status: -1,
    already_send_cart: false,

    latest_cart_id: null,
    selected_point: 0,

    is_order_created: null,
  },
  mutations: {
    loggedIn(state, payload) {
      state.isLoggedIn = true;
      state.user = payload;
    },

    loggingOut(state) {
      state.isLoggingOut = true;
    },

    loggedOut(state) {
      state.isLoggedIn = false;
      state.isLoggingOut = false;
      state.user = null;
    },

    setToken(state, payload) {
      state.token = payload;
    },

    authToken(state, payload) {
      state.authToken = payload;
    },

    generateCode(state, payload) {
      let store_location_id = 5;
      let newFlag = '';
      if (Cookies.get('store_location_id') && Cookies.get('store_location_id') != undefined) {
        store_location_id = Cookies.get('store_location_id');
      }

      if (payload && payload.isSelfCheckout) {
        newFlag = '-selfcheckout';
      }

      // generate qr-code
      state.value = `soco-store${store_location_id}-${randomString(19)}${newFlag}`; // QR Code format soco-store18-1aq2bolo01rufssn5t4
      state.createdAt = new Date();

      // reset session
      if (!state.authToken) {
        state.isLoggedIn = false;
        state.user = null;
      }
    },

    destroyCode(state, payload) {
      state.value = null;
      state.createdAt = null;
    },

    loginStatus(state, data) {
      state.login_status = data;
    },

    SET_CART_ID(state, data) {
      state.latest_cart_id = data;
    },
    SET_SELECTED_POINT(state, data) {
      state.selected_point = data;
    },
    SET_ALREADY_SEND_CART(state, toggle) {
      state.already_send_cart = toggle;
    },
    SET_IS_ORDER_CREATED(state, data) {
      state.is_order_created = data;
    },
  },
  actions: {
    setSelectedPoint({ commit }, point) {
      commit('SET_SELECTED_POINT', point);
    },
    generateQrCode(state, payload) {
      state.commit('generateCode', payload);
    },

    destroyQrCode(state) {
      state.commit('destroyCode');
      state.commit('loggedOut');
    },

    getToken({ commit, state, dispatch }, data) {
      if (state.loggedIn) {
        // already logged in
        return;
      }

      // get token then update user profile data
      return axios
        .get(`${config.MS_SOCO_PUBLIC_API_URL}/user/me`, {
          headers: {
            Authorization: `Bearer ${state.authToken}`,
          },
        })
        .then(async (response) => {
          commit('setProfile', response.data.data);
          commit('loggedIn', response.data.data);

          if (data && 'redirect' in data) {
            if (data.direct_to_order && data.redirect === '/vmcola/order') {
              data.data.$router.push({ path: data.redirect });
            } else if (data.is_vmcola) {
              return await dispatch('sendCartData', data);
            } else {
              data.data.$router.push({ path: data.redirect });
            }
          }
        })
        .then(() => {
          commit('loginStatus', 1);
        })
        .catch((err) => {
          console.log(err);

          if (data.is_soco_vending_machine_event) {
            commit('loginStatus', 0);
            setTimeout(() => {
              commit('loginStatus', -1);
            }, 3000);
            data.data.$router.push({ path: '/soco-vending-machine-event/qr-not-found' });
          } else if (data.is_vmcola) {
            commit('loginStatus', 0);
            setTimeout(() => {
              commit('loginStatus', -1);
            }, 3000);
          }
          if (err.response.status == 401) {
            // either user logged out or have not logged in
            console.log('either user logged out or have not logged in');
          } else {
            console.error(err);
          }
        });
    },

    destroyQrSession({ state, commit, dispatch }, payload) {
      // commit('loggingOut'); // start loader
      axios
        .get(`${config.MS_SOCO_PUBLIC_API_URL}/auth/logout`, null, {
          headers: {
            Authorization: `Bearer ${state.authToken}`,
          },
        })
        .then(() => {
          dispatch('destroyQrCode'); // logout, destroy QR Code and stop loading
          if (payload && payload.router && payload.redirectUrl) {
            // redirect to specified login page
            payload.router.push({
              path: payload.redirectUrl,
            });
            this._vm.$toasted.global.show('Logout berhasil!');
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    setUser(state, payload) {
      state.commit('setUser', payload);
    },

    async placeOrder({ state, commit, rootState }, context) {
      const [cart, loc] = await Promise.all([JSON.parse(ls.get('cart')), Cookies.get('store_location_id')]);

      let total_price_after_shipping = 0;

      cart.forEach((p, i) => {
        total_price_after_shipping +=
          Number(
            cart[i].raw.detail.combination.price_after_discount
              ? cart[i].raw.detail.combination.price_after_discount
              : cart[i].raw.detail.combination.price
          ) * cart[i].qty;
      });

      const cart_data = {
        _id: state.latest_cart_id,
        total_price_after_shipping: total_price_after_shipping,
        created_at: new Date(),
      };

      if (rootState.vmCola.point_applied && rootState.vmCola.point_applied > 0) {
        cart_data.total_price_after_shipping -= rootState.vmCola.point_applied;
      }

      if (loc) {
        const payload = {
          cart_id: cart_data._id,
          payment_method: 'gopay',
          payment_type: 'GOPAY',
          total_price: cart_data.total_price_after_shipping,
          id_payment_method: '5e5000e517a1de3bb1d7a260',
          is_payment_method_change: false,
          source: 'vending_machine',
          created_at: cart_data.created_at,
          store_id: loc,
        };

        const is_guest = await ls.get('is_guest');

        const headers = {
          'SOC-PLATFORM': 'vending-machine',
          store_id: loc,
        };

        if (state.authToken) {
          headers['Authorization'] = `Bearer ${state.authToken}`;
        }

        if (is_guest) {
          payload.is_guest = true;

          headers['is-guest'] = true;
          headers['guest-checkout'] = true;
        }

        return axios
          .post(`${config.MS_ORDER_API_URL}/users/me/orders`, payload, {
            headers: headers,
          })
          .then((res) => {
            commit('SET_IS_ORDER_CREATED', res.data.data);
            return res;
          })
          .catch((err) => {
            const err_message = get(err, 'response.data.data.message', null);
            const err_code = get(err, 'response.data.data.errorCode', null);

            this._vm.$toasted.global.error(err_message || 'Silakan coba lagi dalam beberapa saat.');
            const redirect_to_home = ['MISSING_PARAMETER', 'order_already_created'].includes(err_code);

            if (redirect_to_home) {
              setTimeout(async () => {
                await Promise.all([
                  ls.remove('cart'),
                  ls.remove('guest_cart_id'),
                  ls.remove('latest_cart_id'),
                  ls.set('is_guest', false),
                ]);

                context.$store.commit('footer_cart', []);
                context.$store.commit('cart_qty', 0);
                context.$store.commit('SET_ALREADY_SEND_CART', false);
                context.$store.state.vmCola.footer_cart = [];
                context.$store.state.vmCola.cart_qty = 0;

                context.$store.dispatch('destroyQrSession', {
                  router: context.$router,
                  redirectUrl: '/vmcola/home',
                });
              }, 1500);
            }
          });
      } else {
        this._vm.$toasted.global.error('Silakan coba lagi dalam beberapa saat.');
      }
    },

    async sendCartData({ state, commit, dispatch }, data) {
      const loc = await Cookies.get('store_location_id');

      if (state.already_send_cart) {
        const cart = await JSON.parse(ls.get('cart'));
        let total_price_after_shipping = 0;

        cart.forEach((p, i) => {
          total_price_after_shipping +=
            Number(
              cart[i].raw.detail.combination.price_after_discount
                ? cart[i].raw.detail.combination.price_after_discount
                : cart[i].raw.detail.combination.price
            ) * cart[i].qty;
        });

        const cart_data = {
          _id: state.latest_cart_id,
          total_price_after_shipping: total_price_after_shipping,
          created_at: new Date(),
        };
        data.cart_data = cart_data;

        if (data.direct_to_order) {
          setTimeout(() => {
            dispatch('createOrderVmCola', data);
          }, 500);
        }
      }

      if (!state.already_send_cart && loc) {
        state.already_send_cart = true;
        let cart_id = '';
        // let total_price_after_shipping = 0;
        // let counting = 0;
        // let order_called = false;

        const cart = await JSON.parse(ls.get('cart'));

        if (!cart || cart == null) {
          data.data.$toasted.info('keranjang kamu kosong, silakan check kembali!');
          data.data.$router.push('/vmcola/products');
          return;
        }

        const slot_product = [];
        for (let i = 0; i < cart.length; i++) {
          if (cart[i].qty <= 0) {
            continue;
          }

          const params = {
            product: {
              id: cart[i].raw.id,
              combination_id: cart[i].raw.combination_id,
              quantity: cart[i].qty,
              slot: cart[i].raw.detail.vending_slot,
            },
            action: 'add',
            store_id: loc,
          };
          if (data.is_guest) {
            params.is_guest = true;
          }
          if (cart_id) {
            params.id = cart_id;
          }

          if (ls.get('PRODUCT_LIST')) {
            const product_list = JSON.parse(ls.get('PRODUCT_LIST'));
            let get_slot_product = -1;
            if (product_list && product_list[loc]) {
              get_slot_product = product_list[loc].find((p) => p.vending_slot == cart[i].raw.detail.vending_slot);
            }
            if (get_slot_product) {
              slot_product.push({
                slot: get_slot_product.vending_slot,
                custBuy: cart[i].qty,
              });
            }
          }

          const headers = {
            'SOC-PLATFORM': 'vending-machine',
            store_id: loc,
          };
          if (state.authToken) {
            headers['Authorization'] = `Bearer ${state.authToken}`;
          }

          if (data.is_guest) {
            headers['is-guest'] = true;
            headers['guest-checkout'] = true;
          }

          await axios
            .post(`${config.MS_CART_API_URL}/user/me/carts`, params, {
              headers: headers,
            })
            .then((res) => {
              const response = res.data.data;
              if (response._id && !cart_id) {
                cart_id = response._id;
                commit('SET_CART_ID', cart_id);

                if (data.is_guest) {
                  ls.set('is_guest', true);
                  ls.set('guest_cart_id', cart_id);
                } else {
                  ls.set('latest_cart_id', cart_id);
                }
              }
            })
            .catch((err) => {
              console.error(err);
              commit('loginStatus', 0);
              setTimeout(() => {
                commit('loginStatus', -1);
              }, 3000);
            });
        }
      }
    },

    async createOrderVmCola({ state, commit, rootState }, data) {
      const cart_data = data.cart_data;
      const loc = await Cookies.get('store_location_id');

      if (rootState.vmCola.point_applied && rootState.vmCola.point_applied > 0) {
        cart_data.total_price_after_shipping -= rootState.vmCola.point_applied;
      }

      if (loc) {
        const payload = {
          cart_id: cart_data._id,
          payment_method: 'gopay',
          payment_type: 'GOPAY',
          total_price: cart_data.total_price_after_shipping,
          id_payment_method: '5e5000e517a1de3bb1d7a260',
          is_payment_method_change: false,
          source: 'vending_machine',
          created_at: cart_data.created_at,
          store_id: loc,
        };

        if (data.is_guest) {
          payload.is_guest = true;
        }

        const headers = {
          'SOC-PLATFORM': 'vending-machine',
          store_id: loc,
        };
        if (state.authToken) {
          headers['Authorization'] = `Bearer ${state.authToken}`;
        }

        await axios
          .post(`${config.MS_ORDER_API_URL}/users/me/orders`, payload, {
            headers: headers,
          })
          .then((res) => {
            commit('SET_IS_ORDER_CREATED', res.data.data);

            commit('loginStatus', -1);
            state.already_send_cart = false;
            data.data.$router.push({
              path: `${data.redirect}`,
              query: {
                id_order: res.data.data._id,
                reference_code: res.data.data.reference_code,
                total: cart_data.total_price_after_shipping,
              },
            });
          })
          .catch((err) => {
            state.already_send_cart = false;
            this._vm.$toasted.global.error('Silakan coba lagi dalam beberapa saat.');
            setTimeout(() => {
              // commit('loginStatus', -1);
            }, 3000);
          });
      } else {
        this._vm.$toasted.global.error('Silakan coba lagi dalam beberapa saat.');
      }
    },
  },
};
