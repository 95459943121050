export default {
  state: {
    listAnswer: [],
    qrcodeState: {
      start: true,
      success: false,
      error: false,
    },
    brand: '',
  },
  mutations: {
    setBrand(state, data) {
      state.brand = data;
    },
    storeAnswer(state, data) {
      state.listAnswer.push(data);
    },
    setStateQRCode(state, { stateName, newVal }) {
      if (stateName === 'success' || stateName === 'error') {
        state.qrcodeState.start = false;
      } else {
        state.qrcodeState.success = false;
        state.qrcodeState.error = false;
      }

      state.qrcodeState[stateName] = newVal;
    },
  },
  actions: {},
  getters: {},
};
