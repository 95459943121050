<template>
  <div id="app">
    <router-view :key="refreshKey" />
    <modalCheckLocation />
  </div>
</template>

<script>
import modalCheckLocation from './components/global/modal/modalCheckLocation';

export default {
  name: 'app',
  components: {
    modalCheckLocation,
  },
  data() {
    return {
      refreshKey: 0,
    };
  },
  computed: {},
  watch: {
    $route: function () {
      const { autoRefresh, refreshFrequency } = this.$route.meta;
      if (autoRefresh === true) {
        setInterval(() => {
          this.refreshKey = Math.random();
        }, refreshFrequency * 60 * 1000);
      }
    },
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
