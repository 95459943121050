import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'assets/sass/style.scss';
import axios from 'axios';
import VeeValidate from 'vee-validate';
import { VLazyImagePlugin } from 'v-lazy-image';
import Toasted from 'vue-toasted';
import { get } from 'lodash';
import './filter';
import $SO from '@/shared/socio-lib';
import '@/shared/RegisteredGlobalComponents';
import Swiper from 'swiper';
import globalMixins from './globalMixins';

import interceptorsSetup from './helpers/interceptors';

Vue.mixin(globalMixins);

const Cookies = require('js-cookie');
const Config = require('~/config/default.env').default;

Vue.prototype.$axios = axios;
axios.defaults.withCredentials = true;

Vue.config.productionTip = false;

Vue.use(VLazyImagePlugin);

window.Cookies = Cookies;
window.Swiper = Swiper;
window.Vue = Vue;
window.axios = axios;
axios.defaults.withCredentials = true;

Vue.prototype.$SO = $SO;
Vue.prototype.$get = get;

// use httpJournal globaly to call Journal w/ credentials
const httpJournal = axios.create({
  baseURL: Config.MS_BJ_PUBLIC_API_URL,
  // withCredentials: true,
});
Vue.prototype.$MS_BJ_PUBLIC_API_URL = httpJournal;

// use httpSOCO globaly to call SOCO w/ credentials
const httpSOCO = axios.create({
  baseURL: Config.MS_SOCO_PUBLIC_API_URL,
  // withCredentials: true,
});
Vue.prototype.$MS_SOCO_PUBLIC_API_URL = httpSOCO;

const httpSociollaPublc = axios.create({
  baseURL: Config.MS_SOCIOLLA_PUBLIC_API_URL,
});
Vue.prototype.$MS_SOCIOLLA_PUBLIC_API_URL = httpSociollaPublc;

// use httpSociolla globaly to call Sociolla w/ credentials
const httpSociolla = axios.create({
  baseURL: Config.MS_SOCIOLLA_API_URL,
  // withCredentials: true,
});
Vue.prototype.$MS_SOCIOLLA_API_URL = httpSociolla;

// NOTE: workaround for VeeValidate + vuetable-2
Vue.use(VeeValidate);
// custom dictionary for veevalidate, defined per v-model name

const dict = {
  custom: {
    warnakulit: {
      required: () => 'Warna Kulit wajib diisi',
    },
    undertone: {
      required: () => 'Undertone wajib diisi',
    },
    jeniskulit: {
      required: () => 'Jenis Kulit wajib diisi',
    },
    kondisikulit: {
      required: () => 'Kondisi Kulit wajib diisi',
    },
    bentukwajah: {
      required: () => 'Bentuk Wajah wajib diisi',
    },
    warnarambut: {
      required: () => 'Warna Rambut wajib diisi',
    },
    panjangrambut: {
      required: () => 'Panjang Rambut wajib diisi',
    },
    jenisrambut: {
      required: () => 'Jenis Rambut wajib diisi',
    },
    kondisirambut: {
      required: () => 'Kondisi Rambut wajib diisi',
    },
    look: {
      required: () => 'Look wajib diisi',
    },
    topik: {
      required: () => 'Topik wajib diisi',
    },
    newsletter: {
      required: () => 'Newsletter wajib diisi',
    },
    first_name: {
      required: () => 'Nama depan belum diisi',
      alpha_dash: () => 'Nama depan tidak boleh menggunakan karakter spesial',
      alpha_spaces: () => 'Nama depan hanya boleh menggunakan alphabet',
      min: () => 'Nama depan minimal 4 karakter',
      max: () => 'Nama depan maksimal 16 karakter',
    },
    last_name: {
      required: () => 'Nama belakang belum diisi',
      alpha_dash: () => 'Nama belakang tidak boleh menggunakan karakter spesial',
      alpha_spaces: () => 'Nama belakang hanya boleh menggunakan alphabet',
      min: () => 'Nama belakang minimal 4 karakter',
      max: () => 'Nama belakang maksimal 16 karakter',
    },
    user_name: {
      required: () => 'Username belum diisi',
      alpha_dash: () => 'Username tidak boleh menggunakan karakter spesial',
      min: () => 'Username minimal 4 karakter',
      max: () => 'Username maksimal 16 karakter',
    },
    hp: {
      required: () => 'Data belum diisi',
      numeric: () => 'Data hanya boleh berisi angka',
      min: () => 'Data minimal 10 angka',
      max: () => 'Data maksimal 18 angka',
    },
    reguser_name: {
      required: () => 'Username belum diisi',
      alpha_dash: () => 'Username tidak boleh menggunakan karakter spesial',
      min: () => 'Username minimal 4 karakter',
      max: () => 'Username maksimal 16 karakter',
    },
    email: {
      required: () => 'Email belum diisi',
      email: () => 'Format email tidak valid',
    },
    forgotemail: {
      required: () => 'Email belum diisi',
      email: () => 'Format email tidak valid',
    },
    password: {
      required: () => 'Data belum diisi',
    },
    repassword: {
      required: () => 'Data belum diisi',
      confirmed: () => 'Konfirmasi password tidak sesuai',
    },
    regpassword: {
      required: () => 'Data belum diisi',
      confirmed: () => 'Konfirmasi password tidak sesuai',
    },
    newpassword: {
      required: () => 'Data belum diisi',
    },
    confirmnewpassword: {
      required: () => 'Data belum diisi',
      confirmed: () => 'Konfirmasi password tidak sesuai',
    },
  },
};

VeeValidate.Validator.localize('en', dict);

// const toastedOption = {
// 	position: 'top-left',
// 	duration: '3000',
// 	singleton: true
// }

const customToastOpt = {
  position: 'top-center',
  singleton: true,
  duration: 5000,
  action: {
    text: '×',
    onClick: (e, toastObject) => {
      toastObject.goAway(0);
    },
  },
};

Vue.use(Toasted, customToastOpt);

Vue.toasted.register(
  'error',
  (payload) => {
    if (!Object.keys(payload).length) {
      return `
          <div class="toast-icon error">
              <i class="icon_error-circle_alt"></i>
          </div>
          <div class="toast-message">
            Error
          </div>`;
    }

    if (!!payload.message) {
      return `
          <div class="toast-icon error">
              <i class="icon_error-circle_alt"></i>
          </div>
          <div class="toast-message">
            ${payload.message}
          </div>`;
    }
    if (typeof payload === 'string') {
      return `
          <div class="toast-icon error">
              <i class="icon_error-circle_alt"></i>
          </div>
          <div class="toast-message">
            ${payload}
          </div>`;
    }
  },
  customToastOpt
);
Vue.toasted.register(
  'show',
  (payload) => {
    if (!Object.keys(payload).length) {
      return `
          <div class="toast-icon">
              <i class="icon_check_alt2"></i>
          </div>
          <div class="toast-message">
            Error
          </div>`;
    }

    if (!!payload.message) {
      return `
          <div class="toast-icon">
              <i class="icon_check_alt2"></i>
          </div>
          <div class="toast-message">
            ${payload.message}
          </div>`;
    }
    if (typeof payload === 'string') {
      return `
          <div class="toast-icon">
              <i class="icon_check_alt2"></i>
          </div>
          <div class="toast-message">
            ${payload}
          </div>`;
    }
  },
  customToastOpt
);

interceptorsSetup(Vue);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
