'use strict'

        
	
module.exports.default= {
	NODE_ENV: 'development',
        MS_SOCO_PUBLIC_API_URL: 'https://soco-api.sociolla.com',
        MS_SOCO_ADMIN_API_URL: 'https://soco-admin-api.sociolla.com',
        MS_SOCIOLLA_API_URL: 'https://www.sociolla.com',
        MS_BJ_PUBLIC_API_URL: 'https://bj-public-api.sociolla.com',
        MS_BJ_ADMIN_API_URL: 'https://bj-admin-api.sociolla.com',
        INSTAGRAM_TOKEN: '4630458412.ba5e2ce.6cf6dfdf4a1d41cbaf0d7a034f3a661b',
        MS_LULLA_API_URL: 'https://lulla-api.sociolla.com',
	MS_CHATBOT_SOCKET_API_URL: 'https://chatbot-socket-api.sociolla.info',
	MS_ANALYTICS_API_URL : 'https://analytics-api.sociolla.com',
	CARASUN_WEB_URL : 'https://www.carasunbeauty.com',
	SOCO_WEB_URL: 'https://www.soco.id',
	BJ_APPS_URL : 'https://www.beautyjournal.id',
        MS_SOCKET_API_URL : 'https://websocket.sociolla.com',
        SOCIOLLA_WEB_URL: 'https://www.sociolla.com',
        FB_APP_ID: "357222842020135",
	SOCIOLLA_VN_ADMIN_URL: 'https://vn-admin.sociolla.info',
        SOCIOLLA_WEB_VN_URL : 'https://vn.sociolla.com',
	REVIEW_WEB_URL: 'https://review.soco.id',
        BJ_WEB_URL: 'https://journal.sociolla.com',
        MS_CHATBOT_API_URL : 'https://chatbot-api.sociolla.info',
	STORE_WEB_URL: 'https://store-kota.sociolla.com',
        BJ_ADMIN_URL: 'https://admin-bj.sociolla.com',
        SOCO_ADMIN_URL: 'https://admin.soco.id',
        OW_API_KEY: '59fed3396a52de044c2066984baafca6',
        MS_ORDER_API_URL: 'https://orders-api.sociolla.com',
        GOOGLE_MAP_KEY: "AIzaSyBGHx4Ks4gE4zngj8mGljR3wg9gNeUfi50",
	LILLA_WEB_URL: 'https://www.lilla.id',
	LULLA_WEB_URL: 'https://www.lilla.id',
	MS_CATALOG_API_URL : 'https://catalog-api6.sociolla.com',
        MS_CART_API_URL: 'https://carts-api.sociolla.com',
        MS_SHIPPING_API_URL: 'https://shipping-api.sociolla.com',
        MS_SOCIOLLA_PUBLIC_API_URL: 'https://api.sociolla.com',
	MS_ACCOUNTS_API_URL : 'https://accounts-api.sociolla.com',
	MS_REVIEW_API_URL: 'https://reviews-api.sociolla.com',
	MS_PAYMENT_API_URL: 'https://payments-api.sociolla.com',
  	RECAPTCHA_SITE_KEY: '6LdGhu0UAAAAAHRDh2bM97TEDB9UMBZSLjtsKe4F',
	      MS_SSO_BROKER_API_URL: 'https://sso-broker.soco.id',
        store_lists: [
                {id: 5, value: 'KOKAS', name: 'Kota Kasablanka Store'},
                {id: 6, value: 'LMP', name: 'Lippo Mall Puri'},
                {id: 12, value: 'TUNJUNGAN PLAZA 6 ',name:'TUNJUNGAN PLAZA 6 '}
        ],
        gpn: {
		url: 'https://bpg.bersama.id/ArtajasaPG/payment/salesRedirect'
	},
	midtrans: {
                env: 'production',
                client_key: 'VT-client-t6txltUBaqVtb3pz',
                url: 'https://api.midtrans.com'
        },
        MS_POS_API_URL: 'https://pos-api.sociolla.com',
        store_id: 5,
        apidoc_users: {
                lazada: {
                        password: "lazada123",
                        docs: [{filename: "product_details.js",name: "Product Review Details"}]
                },
                sociolla: {
                        password: "sociolla@dmin",
                        docs: []
                },
        },
        MS_SOCO_PUBLIC_API_LIST: ['https://soco-api.sociolla.com'],
        MS_BJ_PUBLIC_API_LIST: ['https://bj-public-api.sociolla.com','https://bj-public-api1.sociolla.com','https://bj-public-api2.sociolla.com','https://bj-public-api3.sociolla.com','https://bj-public-api4.sociolla.com'],
	 MS_CATALOG_API_LIST: ['https://catalog-api.sociolla.com','https://catalog-api1.sociolla.com','https://catalog-api2.sociolla.com','https://catalog-api3.sociolla.com','https://catalog-api4.sociolla.com','https://catalog-api5.sociolla.com'],
MS_CART_API_LIST: ['https://carts-api.sociolla.com','https://carts-api1.sociolla.com','https://carts-api2.sociolla.com'],
	STORE_DEEPLINK: 'https://sociolla.app.link/zViOcjLVBpb'
}

