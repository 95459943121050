export default {
  state: {
    category: '',
    subCategory: '',
    subSubCategory: '',
  },
  mutations: {
    setCategory(state, data) {
      state.category = data;
    },
    setSubCategory(state, data) {
      state.subCategory = data;
    },
    setSubSubCategory(state, data) {
      state.subSubCategory = data;
    },
  },
  actions: {},
  getters: {},
};
