<template>
  <!-- <a :href="`${$store.state.SOCO_WEB_URL}/${username}/timeline`" target="_blank">
		<img :src="src" :class="className" v-if="src" onerror="this.src='/static/img/avatar-soco.png';">
		<img src="/static/img/avatar-soco.png" :class="className" v-else>
	</a> -->
  <a href="" target="_blank" @click.prevent>
    <img v-if="src" :src="src" :class="className" onerror="this.src='/static/img/avatar-soco.png';" />
    <img v-else src="/static/img/avatar-soco.png" :class="className" />
  </a>
</template>

<script>
export default {
  name: 'GlobalImageuser',
  props: {
    className: {
      type: String,
      required: true,
    },
    username: {
      required: true,
    },
    src: {
      required: true,
    },
  },
};
</script>
